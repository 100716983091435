// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-encomendas-js": () => import("./../../../src/pages/encomendas.js" /* webpackChunkName: "component---src-pages-encomendas-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-produtos-js": () => import("./../../../src/pages/produtos.js" /* webpackChunkName: "component---src-pages-produtos-js" */),
  "component---src-pages-receitas-js": () => import("./../../../src/pages/receitas.js" /* webpackChunkName: "component---src-pages-receitas-js" */),
  "component---src-templates-receitas-template-js": () => import("./../../../src/templates/receitasTemplate.js" /* webpackChunkName: "component---src-templates-receitas-template-js" */)
}

